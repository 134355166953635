<template>
  <v-container class="mt-5">
    <v-row v-if="verein">
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn x-large rounded icon to="/admin/vereine" exact>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col align-self="center">
            <h1 v-if="verein.name">{{ verein.name }} bearbeiten</h1>
            <h1 v-if="!verein.name">Verein erstellen</h1>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn large rounded text color="red" @click="remove">
              <v-icon>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-switch
          class="ml-auto"
          rounded
          label="Aktiv"
          v-model="verein.active"
          @change="update"
        ></v-switch>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          filled
          rounded
          label="Name"
          v-model="verein.name"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          filled
          rounded
          label="Kurz"
          v-model="verein.kurz"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          filled
          rounded
          label="Beschreibung"
          v-model="verein.beschreibung"
          @change="update"
        ></v-textarea>
      </v-col>
      <v-col cols="12" lg="auto" class="text-center">
        <b>Primäre Vereinsfarbe</b>
        <v-color-picker
          dot-size="25"
          hide-mode-switch
          mode="hexa"
          class="rounded-xl"
          swatches-max-height="200"
          v-model="verein.farben.primary"
          @change="update"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" lg="auto" class="text-center">
        <b>Sekundäre Vereinsfarbe</b>
        <v-color-picker
          dot-size="25"
          hide-mode-switch
          mode="hexa"
          class="rounded-xl"
          swatches-max-height="200"
          v-model="verein.farben.secondary"
          @change="update"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" lg="4" align-self="center">
        <v-row justify="center">
          <v-col cols="6" align-self="center">
            <v-img :src="verein.logo" width="100%"></v-img>
          </v-col>
          <v-col cols="8" align-self="center">
            <v-btn
              block
              rounded
              color="primary"
              @click.stop="logo.dialog = true"
            >
              LOGO HOCHLADEN
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog
          v-model="logo.dialog"
          :max-width="$vuetify.breakpoint.mdAndUp ? '700' : 'none'"
          :overlay-opacity="$vuetify.breakpoint.mdAndUp ? 0.85 : undefined"
          :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
          :transition="
            $vuetify.breakpoint.mdAndUp
              ? 'dialog-transition'
              : 'dialog-bottom-transition'
          "
          :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
          :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
          content-class="rounded-xl"
        >
          <v-card class="rounded-xl">
            <v-toolbar color="primary" dark>
              <v-btn icon @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Logo hochladen</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text :loading="logo.uploading" @click="upload()">
                  Speichern
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row justify="center" class="my-4">
                <v-col :cols="logo.imageData ? 12 : 10">
                  <v-image-input
                    v-model="logo.imageData"
                    :image-quality="0.85"
                    clearable
                    accept="image/*"
                    :hideActions="logo.imageData ? false : true"
                    :imageWidth="$vuetify.breakpoint.mdAndUp ? 256 : 192"
                    :imageMinScaling="'contain'"
                    :imageHeight="$vuetify.breakpoint.mdAndUp ? 256 : 192"
                    :full-width="true"
                    image-format="png"
                  />
                </v-col>
                <v-col cols="12" class="text-center" v-if="!logo.imageData">
                  Für eine gute Darstellung wähle bitte ein Bild mit mindestens
                  256 x 256 Pixeln.
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions right>
              <v-btn
                color="primary"
                right
                rounded
                :loading="logo.uploading"
                @click="upload()"
              >
                Speichern
              </v-btn>
              <v-btn color="secondary" right rounded @click="closeDialog()">
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import router from '../../../router'

export default {
  name: 'Verein',
  data() {
    return {
      verein: '',
      vdoc: '',
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
    }
  },
  mounted() {
    this.setVerein()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
    }),
  },
  methods: {
    update() {
      updateDoc(this.vdoc, this.verein)
    },
    async remove() {
      const deletion = await deleteDoc(doc(this.db, 'Vereine', this.verein.id))
      router.push('/admin/vereine')
    },
    setVerein() {
      this.vereine.all.forEach((v) => {
        if (v.id == this.$route.params.verein) {
          this.verein = v
        }
      })
      if (this.verein) {
        this.vdoc = doc(this.db, 'Vereine', this.verein.id)
      } else {
        setTimeout(() => {
          this.setVerein()
        }, 50)
      }
    },
    closeDialog() {
      this.logo = {
        dialog: false,
        uploading: false,
        imageData: '',
      }
    },
    upload() {
      if (this.logo.imageData) {
        this.verein.logo = this.logo.imageData
        this.logo.uploading = false
        this.logo.imageData = ''
        this.logo.dialog = false

        this.update()
      }
    },
  },
}
</script>
